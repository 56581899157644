import { request } from '@/services';
import { Request } from '@/services/requests/request';
import { AxiosRequestConfig } from 'axios';
import { AnyObject } from 'element-plus/lib/el-table/src/table.type';

interface pageInfo{
  pageNum: number
  pageSize: number
  total: number
  totalPage: number
}
interface tableItem{
  id: number
  name: string
  relateAppId: string
  relateUser: string
  status: number
  createTimestamp: number
  operateTimestamp: number
  icon: string
  iconSource: string
  operatorId: string
  creatorId: string
  basicUserNum: number
  basicHotNum: number
  backgroundImg: string
  url: string
  creatorNickName: string
  creatorIcon: string
  operatorNickName: string
  operatorIcon: string
}
// 添加用户列表信息
interface userItem{
  creatorIcon: string;
  createTime: number;
  creatorId: string;
  creatorNickName: string;
  roleId: number;
  icon: string;
  nickName: string;
  createTimeStamp: number;
  loginType: number;
  totalPostAuditNum: number;
  totalPostSendNum: number;
  userId: string;
  communityList: {
    id: number;
    name: string;
  }[];
}
interface communitInterface  {
  pageInfo: pageInfo
  //   tableList?: Array<tableItem>
  communities: Array<tableItem>
}
interface userInterface  {
  pageInfo?: pageInfo
  userDetailList?: Array<userItem>
}
interface resultInterface<T> {
  ret: number;
  msg: string;
  data: T;
}

// 搜索
export function getCommunitSearchList(req: AnyObject):
Promise<Request<AxiosRequestConfig, resultInterface<communitInterface>> | AnyObject> {
  return request<AxiosRequestConfig, resultInterface<communitInterface>>({
    url: '/api/manage/community/batch',
    method: 'get',
    params: {
      ...req,
    },
  });
}
// 删除
export function deleteTableDataApi(req: AnyObject):
Promise<Request<AxiosRequestConfig, resultInterface<communitInterface>>> {
  return request<AxiosRequestConfig, resultInterface<communitInterface>>({
    url: '/api/manage/community/status',
    method: 'put',
    params: {
      ...req,
    },
  });
}
// 通过appid拉取图片
interface appInfo{
  appId: string
  appName: string
  icon: string
}
interface appInfoInterface  {
  appInfo: appInfo
}
// 通过appid拉取图片
export function getAppIdApi(req: AnyObject): Promise<Request<AxiosRequestConfig, resultInterface<appInfoInterface>>> {
  return request<AxiosRequestConfig, resultInterface<appInfoInterface>>({
    url: '/api/manage/community/appinfo',
    method: 'get',
    params: {
      ...req,
    },
  });
}

// 社区状态变更 社区删除
// "status": number 社区状态：1-下线，2-上线，3-删除
export function changeStatusApi(req: AnyObject):
Promise<Request<AxiosRequestConfig, resultInterface<communitInterface>>> {
  return request<AxiosRequestConfig, resultInterface<communitInterface>>({
    url: '/api/manage/community/status',
    method: 'put',
    params: {
      ...req,
    },
  });
}

//   保存社区接口 ---社区创建、社区编辑
// id为0为创建，id！=0为社区编辑
// "iconSource": number 图标来源、用于前端显示：1-应用图标，2-上传
export function editCommunitApi(req: AnyObject):
Promise<Request<AxiosRequestConfig, resultInterface<communitInterface>>> {
  return request<AxiosRequestConfig, resultInterface<communitInterface>>({
    url: '/api/manage/community/create',
    method: 'post',
    data: req,
  });
}
// 获取非管理员用户列表-过滤掉C端用户(可条件检索，条件空即检索所有非管理员用户） ：创建社区-关联非管理员用户时用
export function getUserListApi(req: AnyObject):
Promise<Request<AxiosRequestConfig, resultInterface<userInterface>> | AnyObject> {
  return request<AxiosRequestConfig, resultInterface<userInterface>>({
    url: '/api/manage/user/query_non_administrator_list',
    method: 'post',
    data: req,
  });
}

interface rspFieldInfo {
  fileUrl: string
}
// 社区上传社区icon
export function uploadImage(req: FormData): Promise<Request<AxiosRequestConfig, resultInterface<rspFieldInfo>>> {
  return request<AxiosRequestConfig, resultInterface<rspFieldInfo>>({
    url: '/api/upload',
    method: 'POST',
    data: req,
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
}
