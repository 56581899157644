
import { defineComponent, reactive, ref, unref, toRefs, onMounted, computed } from 'vue';
import { deleteTableDataApi, getAppIdApi, changeStatusApi, getCommunitSearchList, editCommunitApi, getUserListApi, uploadImage } from '../../reactivity/getCommunityData';
import { ElMessage } from 'element-plus';
import { AnyObject } from 'element-plus/lib/el-table/src/table.type';
import dayjs from 'dayjs';
import { useStore } from 'vuex';
export default defineComponent({
  setup() {
    const store = useStore();
    type tag = { roleId: number, nickName?: string, userId: string };
    const addComUserForm = ref();
    const ruleFormsss = ref();
    // 定义社区表单变量
    const ruleForm = reactive({
      id: 0,
      name: '',
      basicUserNum: '',
      basicHotNum: '',
      icon: '',
      relateUser: '',
      relateAppId: '',
    });
    const addInnerUser = () => {
      data.innerVisible = true;
      getUserList();
    };
    const handleCloseInner = () => {
      const form = unref(addComUserForm);
      form.resetFields();
      data.innercurrentPage = 1;
      innerPageInfo.value.pageSize = 5;
      innerPageInfo.value.pageNum = 1;
      data.innerVisible = false;
    };
    const mastNumber = (key: 'id' | 'relateAppId') => {
      Object.keys(data.searchInfo).forEach((k) => {
        if (key === k) {
          data.searchInfo[key] = (data.searchInfo[key].replace(/[^\d.]/g, ''));
        }
      });
    };
    const maxIdNumber = (key: 'userId') => {
      Object.keys(data.addComForm).forEach((k) => {
        if (key === k) {
          data.addComForm[key] = (data.addComForm[key].replace(/[^\d.]/g, ''));
        }
      });
    };
    const checkId = (rule: AnyObject, value: string) => {
      if (value) {
        if (Number(value) > 2147483647) {
          return Promise.reject('输入ID无效');
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };
    const checkNum = (rule: AnyObject, value: string) => {
      if (value) {
        if (Number(value) > 2147483647) {
          return Promise.reject('输入数量过长');
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };
    const checkAppId = (rule: AnyObject, value: string) => {
      if (value) {
        if (Number(value) > 9223372036854775807) {
          return Promise.reject('输入APP-ID无效');
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };
    // 校验表单
    const rules = reactive({
      name: [{
        required: true,
        message: '请输入社区名称',
        trigger: 'blur',
      }],
      basicUserNum: [{
        validator: checkNum,
        trigger: 'blur',
      }],
      basicHotNum: [{
        validator: checkNum,
        trigger: 'blur',
      }],
      relateAppId: [{
        validator: checkAppId,
        message: 'APP-ID无效',
      }],
      icon: [{
        required: true,
        message: '请上传图片',
        trigger: 'change',
      }],
    });
    const maxNumber = (key: 'basicUserNum' | 'basicHotNum' | 'relateAppId') => {
      Object.keys(ruleForm).forEach((k) => {
        if (key === k) {
          ruleForm[key] = (ruleForm[key].replace(/[^\d.]/g, ''));
        }
      });
    };
    const add = () => {
      data.isAdd = true;
      data.dialogEditVisible = true;
    };
    // 时间戳格式化
    const relDateFormat = (num: number) => dayjs(num * 1000).format('YYYY-MM-DD HH:mm:ss');
    const innerPageInfo = ref({
      totalPage: 0,
      pageNum: 1,
      pageSize: 5,
      total: 0,
    });
    const tagsList = reactive([]);
    const optArr1: tag[] = [];
    const optArr2: tag[] = [];
    const optArr3: tag[] = [];
    const data = reactive({
      isAdd: true, // true，创建社区，false，编辑
      pageInfo: {
        totalPage: 0,
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      tableList: [],
      statusArr: [ // 下拉框选项内容
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '下线中',
        },
        {
          value: 2,
          label: '上线中',
        },
        {
          value: 3,
          label: '已删除',
        },
      ],
      ueserTypeArr: [ // 下拉框选项内容
        {
          value: 2,
          label: '厂商',
        },
        {
          value: 3,
          label: '版主',
        },
        {
          value: 4,
          label: '写手',
        },
      ],
      activeNames: ['1'],
      userRole: true, // false
      isZhankai: true,
      innercurrentPage: 1,
      currentPage: 1,
      dialogEditVisible: false, // 编辑dialog展示
      rowObject: {
        basicUserNum: 0,
        basicHotNum: 0,
        createTimestamp: '',
        creatorIcon: '',
        creatorId: '',
        creatorNickName: '',
        icon: '',
        iconSource: 0,
        id: 0,
        name: '',
        operateTimestamp: '',
        operatorIcon: '',
        operatorId: '',
        operatorNickName: '',
        relateAppId: '',
        relateUser: '',
        status: 0,
        url: '',
      }, // 模拟一个row一行数据信息  编辑模态框绑定数据
      rawData: {},	// 获取表单时clone的原始数据————编辑copy
      diffData: {},	// 差异——编辑提交的内容
      searchInfo: { // 搜索里所有可填的信息
        id: '', // 搜索条件ID
        name: '', // 搜索条件昵称
        createTime: [], // [], // 搜索 创建时间到结束时间
        relateAppId: '', // 搜索关联用户qq
        status: null, // 搜索 上线下线状态
        pageNum: 1,
        pageSize: 5,
      },
      delRowId: 0, // 查找到要删除的一行的id
      delIndex: 0, // 当前一行要删除的索引index
      upOrDownRowStatus: 0, // 操作上下线 + 删除操作  ——————————1-下线，2-上线，3-删除
      dialogDelVisible: false, // 删除模态框初始化隐藏
      dialogUpOrDownVisible: false, // 操作状态----模态框初始化隐藏
      innerVisible: false, // 内嵌 添加社区的关联用户
      dialogAddVisible: false, // 创建社区模态框
      gridData: [],
      filmsTags: optArr1, // 添加得厂商
      webMasters: optArr2, // 添加得版主
      writers: optArr3, // 添加得写手
      disableUploadFlag: false, // 上传按钮是否置灰
      addComForm: {
        userId: '',
        nickName: '',
        roleId: undefined,
        pageNum: 1,
        pageSize: 5,
      },
      tableLoading: false,
      userTableLoading: false,
      appName: '',
    });
    const refData = toRefs(data);
    const handleChange = () => {
      data.isZhankai = !data.isZhankai;
    };
    const handleSizeChangeFn = (val: number) => { // 切换每页显示条数
      data.searchInfo.pageNum = 1; //
      data.searchInfo.pageSize = val;
      data.currentPage = 1;
      getCommunityList();
    };
    const handleCurrentChange = (val: number) => {
      data.searchInfo.pageNum = val;
      getCommunityList();
    };
    const handleEdit = (index: number, row: {id: number}) => {
      data.isAdd = false;
      data.dialogEditVisible = true;
      getCommunitSearchList({ id: row.id }).then((res) => {
        console.log(res);
        const req = res.data;
        if (req?.ret === 0) {
          const community = req.data.communities[0];
          ruleForm.id = community.id;
          ruleForm.relateUser = community.relateUser;
          ruleForm.relateAppId = community.relateAppId !== '0' ? community.relateAppId : '';
          ruleForm.name = community.name;
          ruleForm.basicUserNum = community.basicUserNum;
          ruleForm.basicHotNum = community.basicHotNum;
          ruleForm.icon = community.icon;
          data.rawData =  JSON.parse(JSON.stringify(ruleForm));
          const ruleUserArr = ruleForm.relateUser ? JSON.parse(ruleForm.relateUser) : [];
          ruleUserArr.forEach((item: {roleId: number; nickName?: string; userId: string}) => {
            console.log(item.roleId);
            if (item.roleId === 2) {
              data.filmsTags.push(item);
            } else if (item.roleId === 3) {
              data.webMasters.push(item);
            } else if (item.roleId === 4) {
              data.writers.push(item);
            }
          });
        } else {
          ElMessage.error(req?.msg);
        }
      });
    };
    // 比较编辑差异的方法
    const diffFormData  = () => {
      const relateUserArr = [...data.filmsTags, ...data.writers, ...data.webMasters];
      ruleForm.relateUser = JSON.stringify(relateUserArr);
      const rData: AnyObject = { ...data.rawData };
      const dData: AnyObject = { ...data.diffData };
      const formO: AnyObject = JSON.parse(JSON.stringify(ruleForm));
      Object.getOwnPropertyNames(rData).forEach((val: string) => {
        if (rData[val] !== formO[val]) {
          if (!data.diffData) { // 未修改
            data.diffData = {};
          }
          if (val === 'basicUserNum' || val === 'basicHotNum' || val === 'relateAppId') {
            dData[val] = Number(formO[val]);
          } else  {
            dData[val] = formO[val];
          }
        }
        console.log('dData:', dData);
      });
      editCommunitApi({ community: { ...dData, id: ruleForm.id } })
        .then((res) => {
          console.log('res:', res);
          const req = res.data;
          if (req?.ret === 0) {
            ElMessage.success({
              message: '编辑成功',
              type: 'success',
            });
            resetComForm();
            getCommunityList();
          } else {
            ElMessage.error(req?.msg);
          }
        })
        .finally(() => {
          data.dialogEditVisible = false;
          // return true;
        });
    };
    // 社区信息保存
    const clickEdit =  () => {
      const form = unref(ruleFormsss);
      if (!form) return;
      try {
        form.validate((valid: boolean) => {
          if (valid) {
            const relateUserArr = [...data.filmsTags, ...data.writers, ...data.webMasters];
            const relateUser = JSON.stringify(relateUserArr);
            if (data.isAdd) {
              editCommunitApi({ community: {
                id: 0,
                name: ruleForm.name,
                relateAppId: Number(ruleForm.relateAppId),
                icon: ruleForm.icon,
                basicUserNum: Number(ruleForm.basicUserNum),
                basicHotNum: Number(ruleForm.basicHotNum),
                relateUser,
              } }).then((res) => {
                const req = res.data;
                if (req?.ret === 0) {
                  ElMessage.success({
                    message: '创建成功',
                    type: 'success',
                  });
                  resetComForm();
                  getCommunityList();
                } else {
                  ElMessage.error(req?.msg);
                }
              });
            } else {
              diffFormData();
            }
            // data.dialogEditVisible = false;
          } else {
            return false;
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    const handleDelete = (index: number, row: { id: number; status: number;}) => {
      // 删除模态框展示
      data.delRowId = row.id;
      data.delIndex = index;
      data.upOrDownRowStatus = 3;
      data.dialogDelVisible = true;
    };
    const deleteTableData = () => { // 删除操作
      deleteTableDataApi({ id: data.delRowId, status: data.upOrDownRowStatus })
        .then((res) => {
          const req = res.data;
          if (req?.ret === 0) {
            ElMessage.success({
              message: '删除成功',
              type: 'success',
            });
            getCommunityList();
          } else {
            ElMessage.error(req?.msg);
          }
        })
        .finally(() => {
          data.dialogDelVisible = false;
          // return true;
        });
    };
    // 重置添加社区表单
    const resetComForm = () => {
      const form = unref(ruleFormsss);
      form.resetFields();
      data.filmsTags = [];
      data.webMasters = [];
      data.writers = [];
      data.dialogEditVisible = false;
    };
    const handleClose = () => {
      resetComForm();
      data.dialogEditVisible = false;
    };
    // 关闭创建-清除填写内容
    const handleAddClose = () => {
      data.dialogAddVisible = false;
    };
    const getCommunityList = () => {
      data.tableLoading = true;
      const createBeginTime = data.searchInfo.createTime[0] && new Date(data.searchInfo.createTime[0]).getTime() / 1000;
      const createEndTime = data.searchInfo.createTime[1] && new Date(data.searchInfo.createTime[1]).getTime() / 1000;
      const searchForm = {
        id: data.searchInfo.id,
        name: data.searchInfo.name,
        relateUserId: data.searchInfo.relateAppId,
        createBeginTime,
        createEndTime,
        status: data.searchInfo.status,
        pageNum: data.searchInfo.pageNum,
        pageSize: data.searchInfo.pageSize,
      };
      getCommunitSearchList(searchForm).then((res) => {
        // 请求成功
        if (res.data && res.data.ret === 0) {
          data.tableList = res.data.data.communities;
          data.pageInfo = res.data.data.pageInfo;
        } else {
          ElMessage.error(res.data.msg);
        }
      })
        .finally(() => {
          data.tableLoading = false;
        });
    };
    const searchFormsss = ref();
    // 搜索
    const handlesearch = () => {
      const form = unref(searchFormsss);
      if (!form) return;
      form.validate((valid: boolean) => {
        if (valid) {
          data.currentPage = 1;
          data.searchInfo.pageNum = 1;
          data.searchInfo.pageSize = 5;
          getCommunityList();
        } else {
          return false;
        }
      });
    };
    // 表格中获取 关联用户名字
    const getUserArr = (user: string) => {
      if (user) {
        const userArr = JSON.parse(user);
        const userName = userArr.filter((item: AnyObject) => item.nickName);
        const userNameArr = userName.map((item: AnyObject) => item.nickName);
        return userNameArr.join(',');
      }
      return '';
    };
    const clearSearchInfoFn = () => { // 重置按钮FN
      const form = unref(searchFormsss);
      console.log(form, 'form');
      form.resetFields();
      data.currentPage = 1;
      data.searchInfo.pageNum = 1;
      data.searchInfo.pageSize = 5;
      getCommunityList();
    };
    const uploadFile = (files: AnyObject) => {
      console.log(files, 'files');
      const  isTypeTrue = /^image\/(jpeg|png|jpg)$/.test(files.file.type);
      const isLt300K = files.file.size / 1024 < 300;
      if (!isLt300K) {
        ElMessage.error({
          message: '上传图片大小不能超过300K!',
          type: 'error',
          duration: 2000,
        });
        return;
      }
      if (!isTypeTrue) {
        ElMessage.error({
          message: '上传图片格式不对',
          type: 'error',
          duration: 2000,
        });
        return;
      }
      const formData = new window.FormData();
      formData.append('file', files.file);
      uploadImage(formData).then((res) => {
        const { data } = res;
        if (data?.ret === 0) {
          ruleForm.icon = data.data.fileUrl;
        } else {
          ElMessage.error(data?.msg);
        }
      });
    };
    const clickAppId = (appid: number) => {
      // ElMessage.closeAll();
      if (!appid || (Number(appid) > 9223372036854775807)) return;
      getAppIdApi({ appId: appid })
        .then((res) => {
          const req = res.data;
          if (req?.ret === 0) {
            ElMessage.success({
              message: '拉取成功',
              type: 'success',
            });
            data.disableUploadFlag = true; // 上传按钮置灰
            data.appName = req.data.appInfo.appName;
            ruleForm.icon = req.data.appInfo.icon;
          } else if (req?.ret === 101) {
            ElMessage.error('请重新校验 APPID 准确性');
          } else {
            ElMessage.error(req?.msg);
            data.appName = '';
          }
        })
        .finally(() => {
          data.dialogDelVisible = false;
        });
    };
    // handleUpOrDown_ 操作上线-线下点击事件
    const handleUpOrDown = (index: number, row: { id: number; status: number; }) => { // 操作上线模态框展示
      data.delRowId = row.id;
      // eslint-disable-next-line eqeqeq
      if (row.status == 1) { // 当前1为下线
        data.upOrDownRowStatus = 2;
      // eslint-disable-next-line eqeqeq
      } else if (row.status == 2) { // 当前2为上线
        data.upOrDownRowStatus = 1;
      }
      data.dialogUpOrDownVisible = true;
    };
    const upOrDownTableData = () => { //  操作上下线操作
      changeStatusApi({ id: data.delRowId, status: data.upOrDownRowStatus })
        .then((res) => {
          const req = res.data;
          if (req?.ret === 0) {
            ElMessage.success({
              message: '操作上下线成功',
              type: 'success',
            });
            getCommunityList();
          } else {
            ElMessage.error(req?.msg);
          }
        })
        .finally(() => {
          data.dialogUpOrDownVisible = false;
        });
    };
    const handleAddConnectUser = (index: number, row: { roleId: number; nickName?: string; userId: string }) => {
      // handleAddConnectUser添加关联用户——表格内添加按钮
      // 判断 是否添加过，用userId
      // ElMessage.closeAll();
      if (row.roleId === 2) {
        if (data.filmsTags.findIndex((item: AnyObject) => item.userId === row.userId) !== -1) {
          ElMessage.warning('该角色已经添加');
        } else {
          data.filmsTags.push({
            roleId: row.roleId,
            nickName: row.nickName,
            userId: row.userId,
          });
          ElMessage.success('添加成功');
        }
      } else if (row.roleId === 3) {
        if (data.webMasters.findIndex((item: AnyObject) => item.userId === row.userId) !== -1) {
          ElMessage.warning('该角色已经添加');
        } else {
          data.webMasters.push({
            roleId: row.roleId,
            nickName: row.nickName,
            userId: row.userId,
          });
          ElMessage.success('添加成功');
        }
      } else if (row.roleId === 4) {
        if (data.writers.findIndex((item: AnyObject) => item.userId === row.userId) !== -1) {
          ElMessage.warning('该角色已经添加');
        } else {
          data.writers.push({
            roleId: row.roleId,
            nickName: row.nickName,
            userId: row.userId,
          });
          ElMessage.success('添加成功');
        }
      }
    };
    const handleTagClose = (tag: { roleId: number; nickName?: string; userId: string}) => {
      // 添加关联用户 -- 删除用户tag标签
      if (tag.roleId === 2) {
        const index = data.filmsTags.findIndex(item => item.userId === tag.userId);
        data.filmsTags.splice(index, 1);
      } else if (tag.roleId === 3) {
        const index = data.webMasters.findIndex(item => item.userId === tag.userId);
        data.webMasters.splice(index, 1);
      } else if (tag.roleId === 4) {
        const index = data.writers.findIndex(item => item.userId === tag.userId);
        data.writers.splice(index, 1);
      }
    };
    // 关联社区分页事件
    const innerSizeChangeFn = (val: number) => { // 切换每页显示条数
      data.addComForm.pageNum = 1;
      data.innercurrentPage = 1;
      data.addComForm.pageSize = val;
      getInnerUserList();
    };
    const handleCurrentInnerChange = (val: number) => {
      data.addComForm.pageNum = val;
      getInnerUserList();
    };
    const getInnerUserList = () => {
      data.userTableLoading = true;
      getUserListApi({ ...data.addComForm  }).then((res) => {
        if (res.data?.ret === 0) {
          innerPageInfo.value = res.data.data.pageInfo;
          data.gridData = res.data.data.userDetailList;
          data.innercurrentPage = res.data.data.pageInfo.pageNum;
        } else {
          ElMessage.error(res.data?.msg);
        }
      })
        .finally(() => {
          data.userTableLoading = false;
        });
    };
    // 非管理员用户列表
    const getUserList = () => {
      data.addComForm.pageNum = 1;
      data.innercurrentPage = 1;
      data.addComForm.pageSize = 5;
      getInnerUserList();
    };
    onMounted(() => {
      handlesearch();
    });
    return {
      ...refData,
      // comValue, // mock中表单信息
      //   collapse,
      tagsList,
      data,
      CommunityName: ref(''),
      connectUserQQ: ref(''),
      handleChange,
      handleSizeChangeFn, // 每页最多几条数据
      handleCurrentChange, // 分页-当前第几页
      add, // 创建社区
      handleEdit, // 编辑
      handleDelete, // 删除
      handleClose, // 关闭 编辑模态框
      handleAddClose, // 关闭 创建模态框
      //   getTableData,
      handlesearch, // 搜索
      clearSearchInfoFn, // 充值搜索按钮
      deleteTableData, // 删除操作
      diffFormData, //  比较差异的方法
      clickEdit, // 编辑模态框 确认按钮
      handleUpOrDown, // 操作上下线 按钮-点击事件
      upOrDownTableData, // 上下线操作
      handleTagClose,  // 添加关联用户 -- 删除用户tag标签
      clickAppId, // appid拉取的图片
      handleAddConnectUser, // 添加关联用户 按钮触发
      innerSizeChangeFn, // 关联社区size选择
      handleCurrentInnerChange, // 关联社区当前页修改
      innerPageInfo, // 关联社区page
      addInnerUser,
      getUserList, // 获取非管理用户List
      relDateFormat,
      uploadFile,
      searchFormsss,
      getUserArr, // 表格中获取 关联用户名字
      rules, // 创建社区 表单校验rules
      ruleForm, // 创建社区 表单form
      ruleFormsss, // 创建社区 表单校验
      resetComForm, // 创建社区 重置表单
      addComUserForm,
      userInfo: computed(() => store.getters.userInfo),
      handleCloseInner,
      mastNumber,
      checkId,
      maxNumber,
      maxIdNumber,
    };
  },
});
