<template>
    <div class="crumbs moveL">
        <el-button size="medium" icon="el-icon-plus" type="primary"
        @click="add">
            创建社区
        </el-button>
        <input id="check" type="checkbox" />
        <label for="check" class="check-out">展开<i class="el-icon-arrow-down"></i></label>
        <label for="check" class="check-in">收起<i class="el-icon-arrow-up"></i></label>
         <el-form :inline="true" :model="searchInfo"
         ref="searchFormsss" class='searchForm element' label-position="left">
                  <el-form-item label="社区ID：" prop="id"
                  :rules="[{
                    validator:checkId, trigger:'blur'
                  }]">
                    <el-input placeholder="请输入社区ID"  v-model.trim="searchInfo.id"
                    @input="mastNumber('id')">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="社区名称：" prop="name">
                    <el-input placeholder="请输入社区名称"  v-model.trim="searchInfo.name">
                    </el-input>
                  </el-form-item>
                  <el-form-item  label="创建时间：" prop="createTime">
                    <el-date-picker
                    v-model="searchInfo.createTime"
                    type="daterange"
                    :default-time="[
                      new Date(2000, 1, 1, 0, 0, 0),
                      new Date(2000, 2, 1, 23, 59, 59)
                    ]"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="关联用户QQ：" prop="relateAppId" @input="mastNumber('relateAppId')"
                  >
                    <el-input placeholder="请输入关联用户QQ"  v-model="searchInfo.relateAppId">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="社区状态：" prop="status">
                    <el-select v-model="searchInfo.status"  placeholder="请选择" style='' class="el-input" >
                        <el-option v-for="item in statusArr" :key="item.value"
                        :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="query-btn">
                    <el-button class="search-btn" @click="handlesearch" type="primary" >检  索</el-button>
                    <el-button  class="reset-btn"  @click="clearSearchInfoFn" >重  置</el-button>
                  </el-form-item>
                <!-- </el-collapse-item>
            </el-collapse> -->
        </el-form>
    </div>
    <div class="space"></div>
    <el-table
        style="width: 100%;"
        :data="tableList"
        v-loading="tableLoading"
        >
        <el-table-column fixed prop="id" label="社区ID"  align="center"> </el-table-column>
        <el-table-column prop="name" label="社区名称"  align="center"> </el-table-column>
        <el-table-column prop="icon" label="社区ICON"  align="center">
            <template v-slot="scope">
                <div class='imgNameBox'>
                  <div class='img'>
                    <img
                      style="width:64px;height:64px"
                      class="table-td-thumb"
                      :src="scope.row.icon"
                    />
                  </div>
                </div>
              </template>
        </el-table-column>
        <el-table-column label="创建时间"  align="left">
          <template #default="scope">
            <span  v-if="scope.row.createTimestamp !== '0'">
              {{ relDateFormat(Number(scope.row.createTimestamp))}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="creatorNickName" label="创建人" align="center" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
             <template #default="scope" >
                <span style="margin-left: 10px; color:#BFBFBF"
                    v-if="scope.row.status==1">
                    下线中
                </span>
                <span style="margin-left: 10px; color:#67C23A" v-if="scope.row.status==2">
                    上线中
                </span>
                <span style="margin-left: 10px; color:#BFBFBF" v-if="scope.row.status==3">
                    已删除
                </span>
            </template>
        </el-table-column>
        <el-table-column prop="relateUser" label="关联用户"  align="center" show-overflow-tooltip>
          <template #default="scope">
            <span>{{getUserArr(scope.row.relateUser)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="operatorNickName" label="操作人"  align="center">
          <template #default="scope">
            <div>
              {{scope.row.operatorNickName ? scope.row.operatorNickName : scope.row.creatorNickName}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="operateTimestamp" label="操作时间" align="left">
          <template #default="scope">
            <span v-if="scope.row.operateTimestamp !== '0'">
              {{ relDateFormat(Number(scope.row.operateTimestamp))}}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200" align="center">
        <template #default="scope">
            <el-button @click="handleEdit(scope.$index, scope.row)"
             type="text" size="middle" style="color:#4D74FF;"
             :disabled="scope.row.status==3"
             >
                编辑
            </el-button>
            <el-button type="text" size="middle" style="color:#4D74FF;"
            @click="handleUpOrDown(scope.$index, scope.row)"
             :disabled="scope.row.status==3"
            >
                <span style="margin-left: 10px; color:#4D74FF;"
                    v-if="scope.row.status== 2">
                    操作下线
                </span>
                <span style="margin-left: 10px; color:#4D74FF;"  v-if="scope.row.status== 1 || scope.row.status== 0">
                    操作上线
                </span>
            </el-button>
            <el-button v-if="userInfo.roleId === 1"
             @click="handleDelete(scope.$index, scope.row)" type="text"
             size="middle" style="color:#F56C6C;"
             :disabled="scope.row.status==3 || scope.row.status==2 ">
                删除
            </el-button>
        </template>
        </el-table-column>
    </el-table>
    <div class="community-pagination">
        <el-pagination
        style="float:right;margin-top:30px"
        @size-change="handleSizeChangeFn"
        @current-change="handleCurrentChange"
        v-model:current-page="currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pageInfo.pageSize"
        layout="sizes, prev, pager, next"
        :total="pageInfo.total">
        </el-pagination>
    </div>
  <!-- 删除模态框 -->
  <div class="commity-dialog">
    <el-dialog title="删除" width="420px" top="35vh" v-model="data.dialogDelVisible">
      <el-alert
        title="确定要进行删除操作吗？"
        :closable="false"
        class="dialog-alert-bg"
        type="warning"
        show-icon>
      </el-alert>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="medium" @click="data.dialogDelVisible = false">取 消</el-button>
          <el-button size="medium" type="primary" @click="deleteTableData(row)">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <!-- 操作上线下线-模态框 -->
  <div class="commity-dialog">
    <el-dialog :title='data.upOrDownRowStatus == 2?"操作上线":"操作下线" '
    width="420px" top="35vh" v-model="data.dialogUpOrDownVisible">
      <el-alert
        title="确定要进行状态操作改变吗？"
        :closable="false"
        class="dialog-alert-bg"
        type="warning"
        show-icon>
      </el-alert>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="data.dialogUpOrDownVisible = false">取 消</el-button>
          <el-button size="medium" type="primary" @click="upOrDownTableData">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <!-- 编辑模态框 -->
  <el-dialog
        title="社区信息"
        v-model="dialogEditVisible"
        width="540px"
        custom-class="communityForm"
        :before-close="handleClose">
        <el-form
        :rules="rules"
        :model="ruleForm"
        ref="ruleFormsss"
        label-width="126px"
        style="width: 414px; margin-left:12px;height: 600px;
        overflow-y: auto;"
        >
          <el-form-item label="社区名称" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="关联APP-ID" prop="relateAppId">
            <el-input v-model="ruleForm.relateAppId" placeholder="请输入"
            @blur="clickAppId(ruleForm.relateAppId)"  @input="maxNumber('relateAppId')"></el-input>
            <div class="app-name" v-if="appName">{{appName}}</div>
          </el-form-item>
          <el-form-item label="社区ICON" required prop="icon">
            <el-button size="medium" class="upload-btn" @click="clickAppId(ruleForm.relateAppId)">通过APP-ID拉取</el-button>
            <el-upload
              class="upload-demo"
              :ref="myUpload"
              action=''
              :http-request="uploadFile"
              accept="image/jpeg,image/png,image/jpg"
              :show-file-list='false'
            >
              <el-button size="medium"  class="upload-btn">
                <i class="el-icon-upload2" style="margin-right:10px"></i>上传
              </el-button>
              <template #tip>
                <div class="el-upload__tip">
                  只能上传 jpg/png/jepg 文件，且不超过 300kb
                </div>
              </template>
            </el-upload>
            <div class="img-box"  v-if="ruleForm.icon">
              <img :src="ruleForm.icon" style="width:70px;height:70px">
              <img src="./files/success.svg" class="suc-svg">
            </div>
          </el-form-item>
          <el-form-item label="社区基数" v-if="userInfo.roleId === 1">
            <div class="base-box">
              <el-form-item label="基础用户量" size="normal" prop="basicUserNum">
                <el-input v-model="ruleForm.basicUserNum" placeholder="请输入"
                @input="maxNumber('basicUserNum')"></el-input>
              </el-form-item>
              <el-form-item label="基础热度" size="normal" prop="basicHotNum" style="margin-top:20px">
                <el-input v-model="ruleForm.basicHotNum" placeholder="请输入"
                 @input="maxNumber('basicUserNum')"></el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="非管理员用户关联" >
            <el-button type='' @click="addInnerUser" size="medium" class="upload-btn">
              <i class="el-icon-plus" style="margin-right:10px"></i>添加
            </el-button>
            <div class="sq_jishu">
              <div class="sq_jishu_div" v-if="filmsTags.length">
                <div class="sq_jishu_div_role">
                  厂商
                </div>
                <div class="sq_jishu_div_tagAll">
                  <el-tag
                    :key="tag"
                    v-for="tag in filmsTags"
                    closable
                    :disable-transitions="false"
                    @close="handleTagClose(tag)"
                  >
                    {{ tag.nickName }}
                  </el-tag>
                </div>
              </div>
              <div class="sq_jishu_div" v-if="webMasters.length">
                <div class="sq_jishu_div_role">
                  版主
                </div>
                <div class="sq_jishu_div_tagAll">
                  <el-tag
                    :key="tag"
                    v-for="tag in webMasters"
                    closable
                    :disable-transitions="false"
                    @close="handleTagClose(tag)"
                  >
                    {{ tag.nickName }}
                  </el-tag>
                </div>
              </div>
              <div class="sq_jishu_div" v-if="writers.length">
                <div class="sq_jishu_div_role">
                  写手
                </div>
                <div class="sq_jishu_div_tagAll">
                  <el-tag
                    :key="tag"
                    v-for="tag in writers"
                    closable
                    :disable-transitions="false"
                    @close="handleTagClose(tag)"
                  >
                    {{ tag.nickName }}
                  </el-tag>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
            <el-button @click="resetComForm">取 消</el-button>
            <el-button type="primary" @click="clickEdit">保 存</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog
      width="540px"
      title="关联社区添加"
      v-model="innerVisible"
      append-to-body
      custom-class="addComUser"
      top="28vh"
      :before-close="handleCloseInner"
      >
        <div class="addComForm">
            <el-form :model="addComForm" ref="addComUserForm"  label-width="68px" class="addComFormInner" inline>
                <el-form-item label="用户ID"  prop="userId">
                    <el-input v-model="addComForm.userId"  size="mini" placeholder="请输入"
                    @input="maxIdNumber('userId')"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称"  prop="nickName">
                    <el-input v-model="addComForm.nickName" size="mini" placeholder="请输入" ></el-input>
                </el-form-item>
                <el-form-item label="用户类型"  prop="roleId">
                    <el-select v-model="addComForm.roleId" placeholder="请选择" size="mini">
                        <el-option v-for="item in ueserTypeArr" :key="item.value"
                        :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" class="search-btn" size="mini" @click="getUserList">检 索</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="gridData" height="260" :row-style="{height: '60px'}"
            v-loading='userTableLoading' class="gridTable">
                <el-table-column property="userId" label="用户ID" show-overflow-tooltip></el-table-column>
                <el-table-column property="nickName" label="用户昵称" show-overflow-tooltip  width="180">
                  <template #default="scope">
                    <span>
                      {{scope.row.nickName?.length>10?(scope.row.nickName.substring(0,10)+ '...') : scope.row.nickName}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column property="roleId" label="用户类型"  show-overflow-tooltip>
                  <template v-slot="scope">
                    <span>{{scope.row.roleId ===2 ? '厂商' : scope.row.roleId ===3 ? '版主' : '写手'}}</span>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作"  width="100" align="center">
                    <template #default="scope">
                        <el-button @click="handleAddConnectUser(scope.$index, scope.row)"
                        type="button" size="small" class="add-btn">
                            添加
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="community-pagination">
              <el-pagination
              style="float:right;margin-top:0"
              @size-change="innerSizeChangeFn"
              @current-change="handleCurrentInnerChange"
              v-model:current-page="innercurrentPage"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="innerPageInfo.pageSize"
              layout="sizes, prev, pager, next"
              :total="innerPageInfo.total">
              </el-pagination>
          </div>
        </div>
    </el-dialog>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, unref, toRefs, onMounted, computed } from 'vue';
import { deleteTableDataApi, getAppIdApi, changeStatusApi, getCommunitSearchList, editCommunitApi, getUserListApi, uploadImage } from '../../reactivity/getCommunityData';
import { ElMessage } from 'element-plus';
import { AnyObject } from 'element-plus/lib/el-table/src/table.type';
import dayjs from 'dayjs';
import { useStore } from 'vuex';
export default defineComponent({
  setup() {
    const store = useStore();
    type tag = { roleId: number, nickName?: string, userId: string };
    const addComUserForm = ref();
    const ruleFormsss = ref();
    // 定义社区表单变量
    const ruleForm = reactive({
      id: 0,
      name: '',
      basicUserNum: '',
      basicHotNum: '',
      icon: '',
      relateUser: '',
      relateAppId: '',
    });
    const addInnerUser = () => {
      data.innerVisible = true;
      getUserList();
    };
    const handleCloseInner = () => {
      const form = unref(addComUserForm);
      form.resetFields();
      data.innercurrentPage = 1;
      innerPageInfo.value.pageSize = 5;
      innerPageInfo.value.pageNum = 1;
      data.innerVisible = false;
    };
    const mastNumber = (key: 'id' | 'relateAppId') => {
      Object.keys(data.searchInfo).forEach((k) => {
        if (key === k) {
          data.searchInfo[key] = (data.searchInfo[key].replace(/[^\d.]/g, ''));
        }
      });
    };
    const maxIdNumber = (key: 'userId') => {
      Object.keys(data.addComForm).forEach((k) => {
        if (key === k) {
          data.addComForm[key] = (data.addComForm[key].replace(/[^\d.]/g, ''));
        }
      });
    };
    const checkId = (rule: AnyObject, value: string) => {
      if (value) {
        if (Number(value) > 2147483647) {
          return Promise.reject('输入ID无效');
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };
    const checkNum = (rule: AnyObject, value: string) => {
      if (value) {
        if (Number(value) > 2147483647) {
          return Promise.reject('输入数量过长');
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };
    const checkAppId = (rule: AnyObject, value: string) => {
      if (value) {
        if (Number(value) > 9223372036854775807) {
          return Promise.reject('输入APP-ID无效');
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    };
    // 校验表单
    const rules = reactive({
      name: [{
        required: true,
        message: '请输入社区名称',
        trigger: 'blur',
      }],
      basicUserNum: [{
        validator: checkNum,
        trigger: 'blur',
      }],
      basicHotNum: [{
        validator: checkNum,
        trigger: 'blur',
      }],
      relateAppId: [{
        validator: checkAppId,
        message: 'APP-ID无效',
      }],
      icon: [{
        required: true,
        message: '请上传图片',
        trigger: 'change',
      }],
    });
    const maxNumber = (key: 'basicUserNum' | 'basicHotNum' | 'relateAppId') => {
      Object.keys(ruleForm).forEach((k) => {
        if (key === k) {
          ruleForm[key] = (ruleForm[key].replace(/[^\d.]/g, ''));
        }
      });
    };
    const add = () => {
      data.isAdd = true;
      data.dialogEditVisible = true;
    };
    // 时间戳格式化
    const relDateFormat = (num: number) => dayjs(num * 1000).format('YYYY-MM-DD HH:mm:ss');
    const innerPageInfo = ref({
      totalPage: 0,
      pageNum: 1,
      pageSize: 5,
      total: 0,
    });
    const tagsList = reactive([]);
    const optArr1: tag[] = [];
    const optArr2: tag[] = [];
    const optArr3: tag[] = [];
    const data = reactive({
      isAdd: true, // true，创建社区，false，编辑
      pageInfo: {
        totalPage: 0,
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      tableList: [],
      statusArr: [ // 下拉框选项内容
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '下线中',
        },
        {
          value: 2,
          label: '上线中',
        },
        {
          value: 3,
          label: '已删除',
        },
      ],
      ueserTypeArr: [ // 下拉框选项内容
        {
          value: 2,
          label: '厂商',
        },
        {
          value: 3,
          label: '版主',
        },
        {
          value: 4,
          label: '写手',
        },
      ],
      activeNames: ['1'],
      userRole: true, // false
      isZhankai: true,
      innercurrentPage: 1,
      currentPage: 1,
      dialogEditVisible: false, // 编辑dialog展示
      rowObject: {
        basicUserNum: 0,
        basicHotNum: 0,
        createTimestamp: '',
        creatorIcon: '',
        creatorId: '',
        creatorNickName: '',
        icon: '',
        iconSource: 0,
        id: 0,
        name: '',
        operateTimestamp: '',
        operatorIcon: '',
        operatorId: '',
        operatorNickName: '',
        relateAppId: '',
        relateUser: '',
        status: 0,
        url: '',
      }, // 模拟一个row一行数据信息  编辑模态框绑定数据
      rawData: {},	// 获取表单时clone的原始数据————编辑copy
      diffData: {},	// 差异——编辑提交的内容
      searchInfo: { // 搜索里所有可填的信息
        id: '', // 搜索条件ID
        name: '', // 搜索条件昵称
        createTime: [], // [], // 搜索 创建时间到结束时间
        relateAppId: '', // 搜索关联用户qq
        status: null, // 搜索 上线下线状态
        pageNum: 1,
        pageSize: 5,
      },
      delRowId: 0, // 查找到要删除的一行的id
      delIndex: 0, // 当前一行要删除的索引index
      upOrDownRowStatus: 0, // 操作上下线 + 删除操作  ——————————1-下线，2-上线，3-删除
      dialogDelVisible: false, // 删除模态框初始化隐藏
      dialogUpOrDownVisible: false, // 操作状态----模态框初始化隐藏
      innerVisible: false, // 内嵌 添加社区的关联用户
      dialogAddVisible: false, // 创建社区模态框
      gridData: [],
      filmsTags: optArr1, // 添加得厂商
      webMasters: optArr2, // 添加得版主
      writers: optArr3, // 添加得写手
      disableUploadFlag: false, // 上传按钮是否置灰
      addComForm: {
        userId: '',
        nickName: '',
        roleId: undefined,
        pageNum: 1,
        pageSize: 5,
      },
      tableLoading: false,
      userTableLoading: false,
      appName: '',
    });
    const refData = toRefs(data);
    const handleChange = () => {
      data.isZhankai = !data.isZhankai;
    };
    const handleSizeChangeFn = (val: number) => { // 切换每页显示条数
      data.searchInfo.pageNum = 1; //
      data.searchInfo.pageSize = val;
      data.currentPage = 1;
      getCommunityList();
    };
    const handleCurrentChange = (val: number) => {
      data.searchInfo.pageNum = val;
      getCommunityList();
    };
    const handleEdit = (index: number, row: {id: number}) => {
      data.isAdd = false;
      data.dialogEditVisible = true;
      getCommunitSearchList({ id: row.id }).then((res) => {
        console.log(res);
        const req = res.data;
        if (req?.ret === 0) {
          const community = req.data.communities[0];
          ruleForm.id = community.id;
          ruleForm.relateUser = community.relateUser;
          ruleForm.relateAppId = community.relateAppId !== '0' ? community.relateAppId : '';
          ruleForm.name = community.name;
          ruleForm.basicUserNum = community.basicUserNum;
          ruleForm.basicHotNum = community.basicHotNum;
          ruleForm.icon = community.icon;
          data.rawData =  JSON.parse(JSON.stringify(ruleForm));
          const ruleUserArr = ruleForm.relateUser ? JSON.parse(ruleForm.relateUser) : [];
          ruleUserArr.forEach((item: {roleId: number; nickName?: string; userId: string}) => {
            console.log(item.roleId);
            if (item.roleId === 2) {
              data.filmsTags.push(item);
            } else if (item.roleId === 3) {
              data.webMasters.push(item);
            } else if (item.roleId === 4) {
              data.writers.push(item);
            }
          });
        } else {
          ElMessage.error(req?.msg);
        }
      });
    };
    // 比较编辑差异的方法
    const diffFormData  = () => {
      const relateUserArr = [...data.filmsTags, ...data.writers, ...data.webMasters];
      ruleForm.relateUser = JSON.stringify(relateUserArr);
      const rData: AnyObject = { ...data.rawData };
      const dData: AnyObject = { ...data.diffData };
      const formO: AnyObject = JSON.parse(JSON.stringify(ruleForm));
      Object.getOwnPropertyNames(rData).forEach((val: string) => {
        if (rData[val] !== formO[val]) {
          if (!data.diffData) { // 未修改
            data.diffData = {};
          }
          if (val === 'basicUserNum' || val === 'basicHotNum' || val === 'relateAppId') {
            dData[val] = Number(formO[val]);
          } else  {
            dData[val] = formO[val];
          }
        }
        console.log('dData:', dData);
      });
      editCommunitApi({ community: { ...dData, id: ruleForm.id } })
        .then((res) => {
          console.log('res:', res);
          const req = res.data;
          if (req?.ret === 0) {
            ElMessage.success({
              message: '编辑成功',
              type: 'success',
            });
            resetComForm();
            getCommunityList();
          } else {
            ElMessage.error(req?.msg);
          }
        })
        .finally(() => {
          data.dialogEditVisible = false;
          // return true;
        });
    };
    // 社区信息保存
    const clickEdit =  () => {
      const form = unref(ruleFormsss);
      if (!form) return;
      try {
        form.validate((valid: boolean) => {
          if (valid) {
            const relateUserArr = [...data.filmsTags, ...data.writers, ...data.webMasters];
            const relateUser = JSON.stringify(relateUserArr);
            if (data.isAdd) {
              editCommunitApi({ community: {
                id: 0,
                name: ruleForm.name,
                relateAppId: Number(ruleForm.relateAppId),
                icon: ruleForm.icon,
                basicUserNum: Number(ruleForm.basicUserNum),
                basicHotNum: Number(ruleForm.basicHotNum),
                relateUser,
              } }).then((res) => {
                const req = res.data;
                if (req?.ret === 0) {
                  ElMessage.success({
                    message: '创建成功',
                    type: 'success',
                  });
                  resetComForm();
                  getCommunityList();
                } else {
                  ElMessage.error(req?.msg);
                }
              });
            } else {
              diffFormData();
            }
            // data.dialogEditVisible = false;
          } else {
            return false;
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    const handleDelete = (index: number, row: { id: number; status: number;}) => {
      // 删除模态框展示
      data.delRowId = row.id;
      data.delIndex = index;
      data.upOrDownRowStatus = 3;
      data.dialogDelVisible = true;
    };
    const deleteTableData = () => { // 删除操作
      deleteTableDataApi({ id: data.delRowId, status: data.upOrDownRowStatus })
        .then((res) => {
          const req = res.data;
          if (req?.ret === 0) {
            ElMessage.success({
              message: '删除成功',
              type: 'success',
            });
            getCommunityList();
          } else {
            ElMessage.error(req?.msg);
          }
        })
        .finally(() => {
          data.dialogDelVisible = false;
          // return true;
        });
    };
    // 重置添加社区表单
    const resetComForm = () => {
      const form = unref(ruleFormsss);
      form.resetFields();
      data.filmsTags = [];
      data.webMasters = [];
      data.writers = [];
      data.dialogEditVisible = false;
    };
    const handleClose = () => {
      resetComForm();
      data.dialogEditVisible = false;
    };
    // 关闭创建-清除填写内容
    const handleAddClose = () => {
      data.dialogAddVisible = false;
    };
    const getCommunityList = () => {
      data.tableLoading = true;
      const createBeginTime = data.searchInfo.createTime[0] && new Date(data.searchInfo.createTime[0]).getTime() / 1000;
      const createEndTime = data.searchInfo.createTime[1] && new Date(data.searchInfo.createTime[1]).getTime() / 1000;
      const searchForm = {
        id: data.searchInfo.id,
        name: data.searchInfo.name,
        relateUserId: data.searchInfo.relateAppId,
        createBeginTime,
        createEndTime,
        status: data.searchInfo.status,
        pageNum: data.searchInfo.pageNum,
        pageSize: data.searchInfo.pageSize,
      };
      getCommunitSearchList(searchForm).then((res) => {
        // 请求成功
        if (res.data && res.data.ret === 0) {
          data.tableList = res.data.data.communities;
          data.pageInfo = res.data.data.pageInfo;
        } else {
          ElMessage.error(res.data.msg);
        }
      })
        .finally(() => {
          data.tableLoading = false;
        });
    };
    const searchFormsss = ref();
    // 搜索
    const handlesearch = () => {
      const form = unref(searchFormsss);
      if (!form) return;
      form.validate((valid: boolean) => {
        if (valid) {
          data.currentPage = 1;
          data.searchInfo.pageNum = 1;
          data.searchInfo.pageSize = 5;
          getCommunityList();
        } else {
          return false;
        }
      });
    };
    // 表格中获取 关联用户名字
    const getUserArr = (user: string) => {
      if (user) {
        const userArr = JSON.parse(user);
        const userName = userArr.filter((item: AnyObject) => item.nickName);
        const userNameArr = userName.map((item: AnyObject) => item.nickName);
        return userNameArr.join(',');
      }
      return '';
    };
    const clearSearchInfoFn = () => { // 重置按钮FN
      const form = unref(searchFormsss);
      console.log(form, 'form');
      form.resetFields();
      data.currentPage = 1;
      data.searchInfo.pageNum = 1;
      data.searchInfo.pageSize = 5;
      getCommunityList();
    };
    const uploadFile = (files: AnyObject) => {
      console.log(files, 'files');
      const  isTypeTrue = /^image\/(jpeg|png|jpg)$/.test(files.file.type);
      const isLt300K = files.file.size / 1024 < 300;
      if (!isLt300K) {
        ElMessage.error({
          message: '上传图片大小不能超过300K!',
          type: 'error',
          duration: 2000,
        });
        return;
      }
      if (!isTypeTrue) {
        ElMessage.error({
          message: '上传图片格式不对',
          type: 'error',
          duration: 2000,
        });
        return;
      }
      const formData = new window.FormData();
      formData.append('file', files.file);
      uploadImage(formData).then((res) => {
        const { data } = res;
        if (data?.ret === 0) {
          ruleForm.icon = data.data.fileUrl;
        } else {
          ElMessage.error(data?.msg);
        }
      });
    };
    const clickAppId = (appid: number) => {
      // ElMessage.closeAll();
      if (!appid || (Number(appid) > 9223372036854775807)) return;
      getAppIdApi({ appId: appid })
        .then((res) => {
          const req = res.data;
          if (req?.ret === 0) {
            ElMessage.success({
              message: '拉取成功',
              type: 'success',
            });
            data.disableUploadFlag = true; // 上传按钮置灰
            data.appName = req.data.appInfo.appName;
            ruleForm.icon = req.data.appInfo.icon;
          } else if (req?.ret === 101) {
            ElMessage.error('请重新校验 APPID 准确性');
          } else {
            ElMessage.error(req?.msg);
            data.appName = '';
          }
        })
        .finally(() => {
          data.dialogDelVisible = false;
        });
    };
    // handleUpOrDown_ 操作上线-线下点击事件
    const handleUpOrDown = (index: number, row: { id: number; status: number; }) => { // 操作上线模态框展示
      data.delRowId = row.id;
      // eslint-disable-next-line eqeqeq
      if (row.status == 1) { // 当前1为下线
        data.upOrDownRowStatus = 2;
      // eslint-disable-next-line eqeqeq
      } else if (row.status == 2) { // 当前2为上线
        data.upOrDownRowStatus = 1;
      }
      data.dialogUpOrDownVisible = true;
    };
    const upOrDownTableData = () => { //  操作上下线操作
      changeStatusApi({ id: data.delRowId, status: data.upOrDownRowStatus })
        .then((res) => {
          const req = res.data;
          if (req?.ret === 0) {
            ElMessage.success({
              message: '操作上下线成功',
              type: 'success',
            });
            getCommunityList();
          } else {
            ElMessage.error(req?.msg);
          }
        })
        .finally(() => {
          data.dialogUpOrDownVisible = false;
        });
    };
    const handleAddConnectUser = (index: number, row: { roleId: number; nickName?: string; userId: string }) => {
      // handleAddConnectUser添加关联用户——表格内添加按钮
      // 判断 是否添加过，用userId
      // ElMessage.closeAll();
      if (row.roleId === 2) {
        if (data.filmsTags.findIndex((item: AnyObject) => item.userId === row.userId) !== -1) {
          ElMessage.warning('该角色已经添加');
        } else {
          data.filmsTags.push({
            roleId: row.roleId,
            nickName: row.nickName,
            userId: row.userId,
          });
          ElMessage.success('添加成功');
        }
      } else if (row.roleId === 3) {
        if (data.webMasters.findIndex((item: AnyObject) => item.userId === row.userId) !== -1) {
          ElMessage.warning('该角色已经添加');
        } else {
          data.webMasters.push({
            roleId: row.roleId,
            nickName: row.nickName,
            userId: row.userId,
          });
          ElMessage.success('添加成功');
        }
      } else if (row.roleId === 4) {
        if (data.writers.findIndex((item: AnyObject) => item.userId === row.userId) !== -1) {
          ElMessage.warning('该角色已经添加');
        } else {
          data.writers.push({
            roleId: row.roleId,
            nickName: row.nickName,
            userId: row.userId,
          });
          ElMessage.success('添加成功');
        }
      }
    };
    const handleTagClose = (tag: { roleId: number; nickName?: string; userId: string}) => {
      // 添加关联用户 -- 删除用户tag标签
      if (tag.roleId === 2) {
        const index = data.filmsTags.findIndex(item => item.userId === tag.userId);
        data.filmsTags.splice(index, 1);
      } else if (tag.roleId === 3) {
        const index = data.webMasters.findIndex(item => item.userId === tag.userId);
        data.webMasters.splice(index, 1);
      } else if (tag.roleId === 4) {
        const index = data.writers.findIndex(item => item.userId === tag.userId);
        data.writers.splice(index, 1);
      }
    };
    // 关联社区分页事件
    const innerSizeChangeFn = (val: number) => { // 切换每页显示条数
      data.addComForm.pageNum = 1;
      data.innercurrentPage = 1;
      data.addComForm.pageSize = val;
      getInnerUserList();
    };
    const handleCurrentInnerChange = (val: number) => {
      data.addComForm.pageNum = val;
      getInnerUserList();
    };
    const getInnerUserList = () => {
      data.userTableLoading = true;
      getUserListApi({ ...data.addComForm  }).then((res) => {
        if (res.data?.ret === 0) {
          innerPageInfo.value = res.data.data.pageInfo;
          data.gridData = res.data.data.userDetailList;
          data.innercurrentPage = res.data.data.pageInfo.pageNum;
        } else {
          ElMessage.error(res.data?.msg);
        }
      })
        .finally(() => {
          data.userTableLoading = false;
        });
    };
    // 非管理员用户列表
    const getUserList = () => {
      data.addComForm.pageNum = 1;
      data.innercurrentPage = 1;
      data.addComForm.pageSize = 5;
      getInnerUserList();
    };
    onMounted(() => {
      handlesearch();
    });
    return {
      ...refData,
      // comValue, // mock中表单信息
      //   collapse,
      tagsList,
      data,
      CommunityName: ref(''),
      connectUserQQ: ref(''),
      handleChange,
      handleSizeChangeFn, // 每页最多几条数据
      handleCurrentChange, // 分页-当前第几页
      add, // 创建社区
      handleEdit, // 编辑
      handleDelete, // 删除
      handleClose, // 关闭 编辑模态框
      handleAddClose, // 关闭 创建模态框
      //   getTableData,
      handlesearch, // 搜索
      clearSearchInfoFn, // 充值搜索按钮
      deleteTableData, // 删除操作
      diffFormData, //  比较差异的方法
      clickEdit, // 编辑模态框 确认按钮
      handleUpOrDown, // 操作上下线 按钮-点击事件
      upOrDownTableData, // 上下线操作
      handleTagClose,  // 添加关联用户 -- 删除用户tag标签
      clickAppId, // appid拉取的图片
      handleAddConnectUser, // 添加关联用户 按钮触发
      innerSizeChangeFn, // 关联社区size选择
      handleCurrentInnerChange, // 关联社区当前页修改
      innerPageInfo, // 关联社区page
      addInnerUser,
      getUserList, // 获取非管理用户List
      relDateFormat,
      uploadFile,
      searchFormsss,
      getUserArr, // 表格中获取 关联用户名字
      rules, // 创建社区 表单校验rules
      ruleForm, // 创建社区 表单form
      ruleFormsss, // 创建社区 表单校验
      resetComForm, // 创建社区 重置表单
      addComUserForm,
      userInfo: computed(() => store.getters.userInfo),
      handleCloseInner,
      mastNumber,
      checkId,
      maxNumber,
      maxIdNumber,
    };
  },
});
</script>
<style lang="scss" scoped src="./index.scss">
</style>
<style lang="scss" scoped>
    .element {
      max-height: 500px;
      overflow: hidden;
      transition: max-height 0.5s;
    }
    :checked ~ .element {
      max-height: 0;
    }
    input[type='checkbox'] {
      position: absolute;
      clip: rect(0 0 0 0);
    }
    :checked ~ .check-in {
      display: none;
    }
    :checked ~ .check-out {
      display: inline-block;
    }
    .check-out {
      display: none;
    }
    .check-in,
    .check-out {
      cursor: pointer;
      float: right;
      height: 36px;
      line-height: 36px;
      font-size: 12px;
      color: #C0C4CC;
    }
</style>
